import * as React from "react";
import Navigation from "../components/Navigation";
import LanguageAndToneSeo from "../components/SEO/LanguageAndToneSeo";
import "../styles/style.css";

const LanguageAndTone = () => {
  return (
    <main>
      <div className="content">
        <LanguageAndToneSeo></LanguageAndToneSeo>
        <Navigation></Navigation>
        <div className="about__section-1">
          <div>
            <div className="glass">
              <h1>Language and Tone</h1>
            </div>
          </div>
          <div className="lat__bullet-circle--container">
            <div>
              <div className="bullet-circle"></div>
              <p>Language has a big role to play in UX designing</p>
            </div>
            <div>
              <div className="bullet-circle"></div>
              <p>Helps define brand image and relationship with the user</p>
            </div>
          </div>
        </div>
        <div className="lat__advice-grid">
          <div class="glass mobile-span">
            <div className="correct">
              <i class="fas fa-check"></i>
            </div>
            <div className="lat__panel-title">Do's</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Research the company's brand and image
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Choose when to be serious and when to be funny
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Consider business and user needs in decision making
              </div>
            </div>
          </div>
          <div class="glass mobile-span">
            <div className="incorrect">
              <i class="fas fa-times"></i>
            </div>
            <div className="lat__panel-title">Don'ts</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Don't blame the user for errors in messages
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Don't forget to check spelling and grammar
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                Don't hesitate to change the tone if the messages need it!
              </div>
            </div>
          </div>
          <div class="glass mobile-span">
            <div className="resources">
              <i class="fas fa-download"></i>
            </div>
            <div className="lat__panel-title">Resources</div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://www.dropbox.com/scl/fi/hsisvj4scqc2d7ffok8e2/Language-and-Tone-Research-Tool.xlsx?dl=0&rlkey=z0u51axallmb5eig0ten0oktc">
                  Language and Tone Research Tool
                </a>
              </div>
            </div>
            <div className="bullet-list">
              <div className="bullet-list__bullet-container">
                <div className="bullet-circle"></div>
              </div>
              <div className="bullet-list__content">
                <a href="https://styleguide.mailchimp.com/voice-and-tone/">
                  Mailchimp's Voice and Tone Guide
                </a>
              </div>
            </div>
          </div>
          <div class="glass span-2">
            <p>Error 104909.</p>
            <p>You pressed something and it went wrong! Try again</p>
          </div>
          <div class="incorrect">
            <i class="fas fa-times"></i>
          </div>
          <div class="glass span-2">
            <p>Oops! We encountered an error</p>
            <p>Let's go back and try again</p>
          </div>
          <div class="correct">
            <i class="fas fa-check"></i>
          </div>
          <div className="full-width">
            TIP: In the second message, the responsibility was not deflected or
            pushed onto the user. When an error occurs, this can affect the
            users emotional state. Be direct, honest but not overly sarcastic
            and never assign blame. Be inclusive and guide the user to possible
            next steps.
          </div>
        </div>
      </div>
    </main>
  );
};

export default LanguageAndTone;
